/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 100%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQFA//EABcBAQEBAQAAAAAAAAAAAAAAAAQAAQL/2gAMAwEAAhADEAAAAc+yeoqsZ0INMOsCv//EABsQAAMAAgMAAAAAAAAAAAAAAAECAwASEBET/9oACAEBAAEFAoTXopI5RdGk409JhbuHfn//xAAZEQEAAgMAAAAAAAAAAAAAAAABABECECH/2gAIAQMBAT8BDEI1fN//xAAbEQAABwEAAAAAAAAAAAAAAAAAAQIQERIhMf/aAAgBAgEBPwFVpBc1/wD/xAAcEAADAAEFAAAAAAAAAAAAAAAAARECEiAiMWH/2gAIAQEABj8C1ZK+EeKRBFbRx62f/8QAHBAAAgMBAAMAAAAAAAAAAAAAAREAITFRIEFh/9oACAEBAAE/IUrQ5DnhCAo33wwC4UL+T1pc2NuAV+H/2gAMAwEAAgADAAAAEKz/AAD/xAAYEQEAAwEAAAAAAAAAAAAAAAABABAxQf/aAAgBAwEBPxArkIXF/wD/xAAZEQEAAgMAAAAAAAAAAAAAAAABABARIUH/2gAIAQIBAT8QSz3Go6v/xAAeEAEBAAICAgMAAAAAAAAAAAABEQAxIVEgcYGR8P/aAAgBAQABPxBNNebQdveCqmqX7MdZg57B1iQmTpL8Mh8whQ16yB2co3+vh//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"harvest moon\"\n        title=\"harvest moon\"\n        src=\"/static/d4acebf1f5cb29b69cf4f6e80a8f6dac/73b64/bluemoon-harvestmoon.jpg\"\n        srcset=\"/static/d4acebf1f5cb29b69cf4f6e80a8f6dac/4d5fb/bluemoon-harvestmoon.jpg 166w,\n/static/d4acebf1f5cb29b69cf4f6e80a8f6dac/73b64/bluemoon-harvestmoon.jpg 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The blue moon is one that happens every three years or so.  The typical lunar cycle works out to be one full moon every 29.53 days however dividing the number of days in a year (365.25) by the lunar cycle yields 12.36 full moons per year.  Eventually, that extra 0.36 moon builds up to an extra full moon in the year.  There would be no blue moons if the number of days in the year equaled 354.36 (29.53 X 12) – there would be no remainder.  The reason behind the term “blue” to describe it relates to Christian clergy in earlier times that used moon phases to plan events like Easter and Lent.  In the event of a 13", React.createElement("sup", null, "th"), " moon that moon was considered to be too early to base Lent on and was therefore deemed a “betrayer moon” –in Old English the word for “betrayer” was “belewe” and this word eventually evolved into “blue”.  People often describe a rare occurrence as happening once in a blue moon and even though that 13", React.createElement("sup", null, "th"), " moon happens once every 3 years it’s actually more frequent than the harvest moon."), "\n", React.createElement(_components.p, null, "The harvest moon is the full moon that occurs closest to the autumn equinox or first day of fall.  In the Northern Hemisphere, the first day of fall is late September so the harvest moon will occur in October.  This type of full moon occurs once every four years or so.  The harvest moon is also known as the “blue corn moon” and will often appear brighter and more colorful (reddish) due to its position relative to Earth.  It was this seemingly bigger, brighter moon that would illuminate the farmer’s way as they harvested their autumn crops, hence “harvest moon”."), "\n", React.createElement(_components.p, null, "Harvest Moon"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
